<template>
	<div class="list">
		<!-- 面包屑导航区域 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>商品管理</el-breadcrumb-item>
			<el-breadcrumb-item>列表</el-breadcrumb-item>
		</el-breadcrumb>

		<!-- 卡片视图区域 -->
		<el-card>
			<!-- 搜索与添加区域 -->
			<el-row :gutter="20">
				<el-col :span="8">
					<el-input placeholder="请输入内容" v-model="searchQuery" clearable @clear="getUserList()">
						<el-button slot="append" icon="el-icon-search" @click="getUserSearch()"></el-button>
					</el-input>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" plain @click="$router.push({path: '/add'})">添加商品</el-button>
				</el-col>
			</el-row>

			<!-- 商品列表区域 -->
			<el-table :data="userlist" border stripe>
				<el-table-column align="center" type="index" :index="getPageIndex" label="序号" width="50">
				</el-table-column>
				<el-table-column label="商品分类">
					<template slot-scope="scope">
						<el-select style="width:60%;" @focus="hand(scope.row)" v-model="scope.row.fenlei_id"
							@change="changeTab">
							<el-option v-for="(item, index) in classList" :key="index" :label="item.text"
								:value="item.fenlei_id"></el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column align="center" label="商品图片">
					<template slot-scope="scope">
						<img v-if="scope.row.img" class="img" :src="scope.row.img" />
						<span v-else>{{ scope.row.key }}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="标题" prop="title"></el-table-column>
				<el-table-column align="center" label="原料" prop="yuanliao"></el-table-column>
				<el-table-column align="center" label="价格" prop="money"></el-table-column>
				<el-table-column label="操作" width="150px">
					<template slot-scope="scope">
						<!-- 修改按钮 -->
						<el-button type="primary" icon="el-icon-edit" size="mini"
							@click="showEditDialog(scope.row)"></el-button>
						<!-- 删除按钮 -->
						<el-button type="danger" icon="el-icon-delete" size="mini"
							@click="removeUserById(scope.row.goods_id)"></el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-card>

		<!-- 修改 -->
		<el-dialog title="修改填报信息" :visible.sync="editDialogVisible" width="66%" @close="editDialogClosed">
			<el-form :model="editForm" ref="editFormRef" label-width="90px">
				<el-form-item label="标题">
					<el-input v-model="editForm.title"></el-input>
				</el-form-item>
				<el-form-item label="原料">
					<el-input v-model="editForm.yuanliao"></el-input>
				</el-form-item>
				<el-form-item label="价格">
					<el-input v-model="editForm.money"></el-input>
				</el-form-item>
				<el-form-item label="商品图片">
					<el-upload class="avatar-uploader" :action="serverUrl" :show-file-list="false"
						:on-success="uploadSuccess" :before-upload="uploadBefore" accept="image/*">
						<img v-if="imgUrl" :src="imgUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-form-item>


			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="editUserInfo">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				classList: [], // 分类数据源
				userlist: [],
				searchQuery: "", // 搜索
				// 控制修改用户对话框的显示与隐藏
				editDialogVisible: false,
				editForm: [{
					img: ''
				}],

				imgUrl: "",
				pageSize: 5,
				currentPage: 1, // 当前页数
				total: 0 // 总数据量
			};
		},
		created() {
			this.getUserList();
			this.getClassList();


		},
		computed: {
			...mapGetters(['serverUrl'])
		},
		methods: {
			//图片上传成功
			uploadSuccess(res, file) {
				this.imgUrl = URL.createObjectURL(file.raw);
				this.editForm.img = res.imgUrl; // 将图片路劲保存在数据库中
				console.log(this.editForm.img)
				console.log(res.imgUrl)
			},
			//图片上传之前
			uploadBefore(file) {
				let limitMax = 5000 * 1024;
				if (file.size > limitMax) {
					this.$messageTips("大小超出限制");
					return false;
				}
			},
			handleSizeChange(val) {
				// 切换每页显示数量触发
				console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.currentPage = 1; // 切换每页显示数量时，回到第一页
				this.getUserList(); // 重新请求数据
			},
			handleCurrentChange(val) {
				// 切换页数触发
				this.currentPage = val;
				this.getUserList(); // 重新请求数据
			},
			//翻页时，确保序号连续
			getPageIndex(index) {
				const page = this.currentPage
				const pagesize = this.pageSize
				// 当前页数 - 1 * 每页数据条数 + index + 1 
				return (page - 1) * pagesize + index + 1
			},

			// 获取所有商品数据

			async getUserList() {
				const {
					data: res
				} = await this.$http.get("getGoods", {
					params: {
						page: this.currentPage,
						pageSize: this.pageSize // 每页数量
					}
				});
				this.userlist = res.list;
				this.total = res.total; // 设置总数据量
				console.log(res.list);
			},

			// 获取分类
			async getClassList() {
				const {
					data: res
				} = await this.$http.get('getClassTabs')
				this.classList = res.list
			},

			// 修改商品类别
			async changeTab(e) {
				console.log(e)
				this.editForm.fenlei_id = e;
				const {
					data: res
				} = await this.$http.get('updateData', {
					params: this.editForm
				})
				this.getUserList()
				console.log(res)
				this.$message.success("修改成功！");
			},

			// 分类框获得焦点时触发
			hand(obj) {
				console.log(obj);
				this.editForm = obj
			},

			// 关闭修改对话框
			editDialogClosed() {
				this.$refs.editFormRef.resetFields();
			},
			// 修改
			showEditDialog(obj) {
				console.log(obj);
				this.editForm = obj;
				this.editDialogVisible = true;
			},
			// 删除
			async removeUserById(goods_id) {
				// 弹框询问用户是否删除数据
				const confirmResult = await this.$confirm(
					"是否删除?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					}
				).catch((err) => err);

				// 如果用户确认删除，则返回值为字符串 confirm
				// 如果用户取消了删除，则返回值为字符串 cancel
				// console.log(confirmResult)
				if (confirmResult !== "confirm") {
					return this.$message.info("已取消删除");
				}

				const {
					data: res
				} = await this.$http.get("delData", {
					params: {
						goods_id,
					},
				});
				console.log(res.data);
				this.$message.success("删除成功！");
				this.getUserList();
			},

			// 确认修改
			async editUserInfo() {
				console.log(111, this.editForm)
				const {
					data: res
				} = await this.$http.get("updateData", {
					params: this.editForm,
				});
				console.log(res);
				// 关闭对话框
				this.editDialogVisible = false;
				this.$message.success("修改成功！");
				this.getUserList();
			},


			// 搜索
			async getUserSearch() {
				const {
					data: res
				} = await this.$http.get("search", {
					params: {
						title: this.searchQuery,
					},
				});
				console.log(res);
				this.userlist = res.list;
				this.total = res.list.length; // 设置总数据量
			},

		},
	};
</script>
<style lang="less" scoped>
	.img {
		width: 120px;
		height: 80px;
	}

	.avatar-uploader {
		width: 200px;
		height: 178px;
		border: 1px dashed gray;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 200px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 200px;
		height: 178px;
		display: block;
	}
</style>